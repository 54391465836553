import React, { Component } from 'react'
import Layout from '../layout'
import SEO from '../seo'
import ThankYou from '../thank-you'
import { WEB_LEGACY_LINK } from '../../../config'
import './style.scss'
export default class LongTermRentalSuccessfully extends Component {
  state = {
    isMobile: true,
  }

  componentDidMount() {
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }

  render() {
    const { isMobile } = this.state
    const { prefix } = this.props
    return (
      <Layout isMobile={isMobile} prefix={prefix} theme="black">
        <SEO
          title="ข้อมูลของคุณถูกส่งเรียบร้อยแล้ว - เช่ารถระยะยาว"
          prefix={prefix}
          path="long-term-rental/successfully"
          canonical={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental/successfully`}
          description="Drivehub เช่ารถระะยาว เปิดบริการแล้ว รับราคาพิเศษ ง่ายๆ เพียงติดต่อเรา!"
          keywords="เช่ารถ, รถเช่า, ระยะยาว, 7วัน, 15 วัน"
        />
        <div className="container long-term-rental-successfully">
          <ThankYou />
        </div>
      </Layout>
    )
  }
}
