import React, { Component } from 'react'
import moment from 'moment-timezone'
import './style.scss'

class ThankYou extends Component {
  get requestedHour() {
    const BKKBookingTime = moment().tz('Asia/Bangkok')
    return BKKBookingTime.format('H')
  }

  get contactTime() {
    if (this.requestedHour >= 20 && this.requestedHour <= 24)
      return 'ภายในวันถัดไป ในเวลาทำการ 08.00 - 20.00 น.'
    if (this.requestedHour >= 0 && this.requestedHour <= 8)
      return 'ภายในเวลาทำการ 08.00 - 20.00 น.'
    return 'ภายใน 15-30 นาที'
  }

  render() {
    const { isListYourCarPage } = this.props
    return (
      <div className="thankyou-container">
        <i className="flaticon-checked-circle" />
        <h2>ข้อมูลของท่านถูกส่งเรียบร้อยแล้ว!</h2>
        <p className="thankyou-container__text">
          {isListYourCarPage ? (
            <React.Fragment>
              เจ้าหน้าที่จะทำการติดต่อกลับภายใน 3-5 วันทำการ
              <br />
              ** ในกรณีที่บริษัท/ร้านของท่าน มีรถเช่ามากกว่า 3 คันขึ้นไป
            </React.Fragment>
          ) : (
            <React.Fragment>
              เจ้าหน้าที่จะทำการติดต่อกลับ<strong>{this.contactTime}</strong>
            </React.Fragment>
          )}
        </p>
        <div className="thankyou-container__contact">
          <p>
            ท่านสามารถสอบถามข้อมูลเพิ่มเติมเกี่ยวกับการร่วมเป็นพันธมิตรกับเราผ่านทาง
          </p>
          <p>
            Line@ :{' '}
            <a
              rel="nofollow"
              href={`${
                isListYourCarPage
                  ? 'https://line.me/R/ti/p/%40drivehub.support'
                  : 'https://line.me/ti/p/%40drivehub'
              }`}
            >
              {isListYourCarPage ? '@drivehub.support' : '@drivehub'}
            </a>
          </p>
          <p>
            หรือ Call Centre โทร.{'  '}
            <a rel="noreferrer" href="tel:+6620385222">
              02-038-5222
            </a>
          </p>
          <div className="contact-img">
            <a
              rel="nofollow"
              href={`${
                isListYourCarPage
                  ? 'https://line.me/R/ti/p/%40drivehub.support'
                  : 'https://line.me/ti/p/%40drivehub'
              }`}
            >
              <img
                src={`/assets/${
                  isListYourCarPage ? 'line_qr_code.jpg' : 'line_qr_code_cs.png'
                }`}
                alt="qrcode"
                id="line-qrcode"
              />
              <img
                src="/assets/line_add_friend.png"
                alt="line-add"
                id="line-add"
              />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ThankYou
